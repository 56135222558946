import Image from 'next/image';
import Link from 'next/link';
import { MultiSubMenuItemProps } from './Utils/constants';
import AccordionMenu from './Utils/AccordionMenu';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { motionVariants } from './Common/GlobalVars';
import { useUserStore } from '@/stores/useUserStore';


const subMenu = [
  {
    title: "Về",
    sub_menu: [
      {
        title: "Về Mooimom",
        link: "/about-mooimom"
      },
      {
        title: "Trở Thành Đại Lý",
        link: "/wholesale"
      },
    ],
    sub_icon: null,
    multi_menu: null,
  },
  {
    title: "giúp đỡ",
    sub_menu: [
      {
        title: "Liên Hệ Với Chúng Tôi",
        link: "/contact-us"
      },
      {
        title: "Phương Thức Thanh Toán",
        link: "/payment-method"
      },
      {
        title: "Thông Tin Giao Hàng",
        link: "/delivery-information"
      },
      {
        title: "Điều kiện về giao dịch chung",
        link: "/transaction-condition"
      },
      {
        title: "Chính sách kiểm hàng",
        link: "/goods-inspection-policy"
      },
      {
        title: "Chính sách đổi/ trả hàng",
        link: "/return-and-exchange"
      },
      {
        title: "Chính sách bảo mật",
        link: "/privacy-policy"
      },
    ],
    sub_icon: null,
    multi_menu: null,
  },
  {
    title: null,
    sub_menu: null,
    sub_icon: null,
    multi_menu: []
  },
  {
    title: "Service",
    sub_menu: [
      {
        title: "CÔNG TY TNHH MOOIMOM VIỆT NAM",
        link: null
      },
      {
        title: "Trụ sở chính: Tầng 7, Số 60, Đường Nguyễn Văn Thủ, Phường Đa Kao, Quận 1, Thành phố Hồ Chí Minh, Việt Nam.",
        link: null
      },
      {
        title: "E-mail: cs.vn@mooimom.com",
        link: null
      },
      {
        title: "Điện thoại: 0768756630 (zalo)",
        link: null
      },
    ],
    sub_single_icon: {
      icon: process.env.NEXT_PUBLIC_STATIC_SITE + "/images/vietnam-gov-logo.png",
      link: "http://online.gov.vn/Home/WebDetails/99298",
      icon_alt: "Online Trade License"
    }
  }
]

const Footer = () => {
  const { userInfo, getUser } = useUserStore();
  const [userData, setUserData] = useState(getUser());
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const render_multi_menu = (menu: Array<MultiSubMenuItemProps>) => {
    return menu.map((multi, index) => (
      <div key={index} className={`${index > 0 ? "mt-12" : ""}`}>
        <div className="text-base font-semibold">{multi.title}</div>
        <div className="mt-4 space-y-4">
          {multi.sub_menu && multi.sub_menu.map((sub, index) => (
            <div className="text-xs" key={index}>
              {sub.link !== null ? (
                <Link href={sub.link} key={index}>{sub.title}</Link>
              ) : <>{sub.title}</>}
            </div>
          ))}
          {multi.sub_icon && (
            <div className="grid grid-cols-4 gap-x-6 gap-y-4">
              {multi.sub_icon.map((icon, index) => (
                <Image src={icon} alt="payment icon" key={index} width={50} height={21} quality={100} className="" />
              ))}
            </div>
          )}
        </div>
      </div>
    ))
  }

  const setOpen = (index: number, open: boolean) => {
    if (open) {
      setOpenIndex(index);
    } else {
      setOpenIndex(null);
    }
  }

  const render_mobile_menu = (menus: Array<any>) => {
    return menus.map((menu, index) => {
      if (menu.title !== null) {
        return <AccordionMenu key={index} openIndex={openIndex} index={index} title={menu.title} sub_menu={menu.sub_menu} sub_icon={menu.sub_icon} multi_menu={menu.multi_menu} open={openIndex === index} setOpen={setOpen} allClosed={openIndex === null} handleRedirect={handleRedirect} sub_single_icon={menu.sub_single_icon} />
      } else if (menu.multi_menu !== null) {
        return menu.multi_menu.map((multi: any, ii: number) => (
          <AccordionMenu key={ii} openIndex={openIndex} index={index * 10 + ii} title={multi.title} sub_menu={multi.sub_menu} sub_icon={multi.sub_icon} multi_menu={multi.multi_menu} open={openIndex === index * 10 + ii} setOpen={setOpen} allClosed={openIndex === null} handleRedirect={handleRedirect} sub_single_icon={menu.sub_single_icon} />
        ))
      } else {
        return <></>
      }
    })
  }

  const handleRedirect = (title: string) => {
    switch (title) {
      case "MOOIMOM Affiliate Program":
        if (userData.user_id === undefined || userData.token === undefined || userData.token === "" ) {
          window.open("/login?redirect=https://affiliate.mooimom.vn", "_blank");
        } else {
          window.open("https://affiliate.mooimom.vn?uid=" + userData.token, "_blank");
        }
        break;

      default:
        break
    }
  }

  useEffect(() => {
    setUserData(getUser());
  }, [getUser, userInfo])

  return (
    <motion.footer
      className="p-0 m-0 bg-mooimom"
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true }}
      variants={motionVariants}
    >
      <div className="container pt-12 pb-20 text-white md:pb-12 font-poppins">
        <div className="justify-between hidden grid-cols-4 md:flex">
          {subMenu.map((menu, index) => (
            <div key={index} className={`${index === subMenu.length - 1 ? "flex justify-end basis-1/3" : ""}`}>
              {menu.title && (
                <div>
                  <div className="text-base font-semibold">{menu.title}</div>
                  <div className="mt-4 space-y-4">
                    {menu.sub_menu && menu.sub_menu.map((sub, index) => (
                      <div className="text-xs" key={index}>
                        {sub.link !== null ? sub.link !== "#" ? (
                          <Link href={sub.link}>{sub.title}</Link>
                        ) : (
                          <div className="cursor-pointer" onClick={() => handleRedirect(sub.title)}>{sub.title}</div>
                        ) : <>{sub.title}</>}
                      </div>
                    ))}
                    {menu.sub_single_icon && (
                      <div>
                        <Link href={menu.sub_single_icon.link}>
                          <Image src={menu.sub_single_icon.icon} alt={menu.sub_single_icon.icon_alt} width={100} height={38} quality={100} />
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {menu.multi_menu && (
                <div>
                  {render_multi_menu(menu.multi_menu)}
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="md:hidden">
          {render_mobile_menu(subMenu)}
        </div>
        <hr className="my-8 bg-white border-t" />
        <div className="items-center justify-between md:flex">
          <div className="items-center md:flex md:space-x-8">
            <div className="text-base font-semibold text-center md:text-start">Follow us</div>
            <div className="flex items-center justify-center mt-4 space-x-8 md:justify-start md:mt-0">
              <Link href="https://www.instagram.com/mooimom.vn/">
                <Image src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/instagram.png"} alt="instagram icon" width={32} height={32} quality={100} />
              </Link>
              <Link href="https://www.facebook.com/Mooimom-VI%E1%BB%86T-NAM-100643319152781">
                <Image src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/facebook.png"} alt="facebook icon" width={32} height={32} quality={100} />
              </Link>
              <Link href="https://www.tiktok.com/@mooimom.vn">
                <Image src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/tiktok.png"} alt="tiktok icon" width={32} height={32} quality={100} />
              </Link>
              <Link href="https://www.youtube.com/channel/UCHdsLrL5WwEQTlQT5RlZWbA">
                <Image src={process.env.NEXT_PUBLIC_STATIC_SITE + "/images/icons/youtube.png"} alt="youtube icon" width={32} height={32} quality={100} />
              </Link>
            </div>
          </div>
          <div className="mt-8 text-xs text-center md:text-start md:mt-0">
            @ Copyright MOOIMOM PTE. LTD - All Rights Reserved
          </div>
        </div>
      </div>
    </motion.footer>
  )
}

export default Footer
